// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'Friend Tech',
		category: 'DBZ FT BOT',
		img: require('@/assets/images/ft.png'),
		link: 'https://ft.dbz.club/',
	},
	{
		id: 2,
		title: 'Stars Arena',
		category: 'DBZ SA BOT',
		img: require('@/assets/images/sa.png'),
		link: 'http://stars.dbz.club',
	},
	{
		id: 3,
		title: 'Tomo social',
		category: 'DBZ TOMO BOT',
		img: require('@/assets/images/tomo.png'),
		link: 'https://tomo.dbz.club'
	},
	{
		id: 4,
		title: 'NewBitcoinCity',
		category: 'DBZ NBC BOT',
		img: require('@/assets/images/nbc.png'),
		link: 'https://nbc.dbz.club'
	},
	{
		id: 5,
		title: 'FT TG ALERT BOT',
		category: 'DBZ TG BOT',
		img: require('@/assets/images/FTTG.png'),
		link: 'https://t.me/dbzft_bot'
	},
	// {
	// 	id: 5,
	// 	title: 'React Social App',
	// 	category: 'Mobile Application',
	// 	img: require('@/assets/images/mobile-project-1.jpg'),
	// },
	// {
	// 	id: 6,
	// 	title: 'Apple Design System',
	// 	category: 'Web Application',
	// 	img: require('@/assets/images/web-project-1.jpg'),
	// },
];

export default projects;
