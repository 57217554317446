export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://dbz.club/',
	},
	{
		id: 2,
		name: 'Twitter',
		icon: 'twitter',
		url: 'https://twitter.com/DBZ_CLUB',
	},

];
